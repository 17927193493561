!function() {
    var validateQuestions = function(event) {
        event.preventDefault();

        var $anwsers = this.querySelectorAll('input');

        for(var i = 0; i < $anwsers.length; i++) {
            if ($anwsers[i].checked === true && ($anwsers[i].getAttribute('data-correct') !== null)) {
                $anwsers[i].parentElement.style.color = 'green';
            } else if($anwsers[i].checked === false && ($anwsers[i].getAttribute('data-correct') === null)) {
                $anwsers[i].parentElement.style.color = 'green';
            } else {
                $anwsers[i].parentElement.style.color = 'red';
            }
        }
    }

    var resetQuestions = function() {
        var $anwsers = this.querySelectorAll('input');

        for(var i = 0; i < $anwsers.length; i++) {
            $anwsers[i].parentElement.removeAttribute('style');
        }
    }

    document.addEventListener('DOMContentLoaded', function() {
        var $questionBlocks = this.querySelectorAll('form.questions');

        for(var i = 0; i < $questionBlocks.length; i++) {
            $questionBlocks[i].addEventListener('submit', validateQuestions)
            $questionBlocks[i].addEventListener('reset', resetQuestions);
        }
    });
}();
