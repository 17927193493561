define(["require", "exports", "jquery"], function (require, exports, $) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var permalinkModal = $('#permalinkModal');
    var permalinkUrlInput = permalinkModal.find('#permalinkUrl');
    var permalinkAlert = permalinkModal.find('.alert-permalink-copy');
    permalinkModal.on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        permalinkUrlInput.val(button.attr('href'));
    });
    permalinkModal.on('hidden.bs.modal', function () {
        permalinkAlert.hide();
    });
    var urlInput = permalinkModal.find('#permalinkUrl');
    urlInput.focus(function () {
        permalinkUrlInput.select();
    });
    permalinkModal.find('.btn-permalink-copy').click(function (event) {
        event.preventDefault();
        urlInput.focus();
        if ($(window).width() >= 992 && permalinkAlert.is(':hidden')) {
            permalinkAlert.slideToggle();
        }
        document.execCommand('copy');
    });
});
