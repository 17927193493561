define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var BuildingLinks = /** @class */ (function () {
        function BuildingLinks() {
        }
        BuildingLinks.prototype._create = function () {
            var _this = this;
            this.modal = this.element.find('#main-modal');
            this.loadingModalTemplate = this.modal.find('.modal-content').clone();
            this.element.click(function (e) {
                var target = e.target;
                if (!(target instanceof HTMLElement)) {
                    return;
                }
                var $target = $(target);
                if (!$target.hasClass('tx-buildings-building-link')) {
                    return;
                }
                if ($target.hasClass('tx-buildings-map-link')) {
                    return;
                }
                _this.showBuildingInfo($target);
                e.preventDefault();
            });
        };
        BuildingLinks.prototype.loadModalContent = function (ajaxUrl) {
            this.modal.data('last-load-url', ajaxUrl);
            this.modal.find('.modal-content').replaceWith(this.loadingModalTemplate.clone());
            // We must call find() once more because the original modal-content is replaced and the above
            // reference is not valid any more.
            this.modal.find('.modal-content').load(ajaxUrl);
        };
        BuildingLinks.prototype.showBuildingInfo = function (buildingLink) {
            var ajaxUrl = buildingLink.attr('data-tooltip-link');
            this.modal.modal('show');
            if (this.modal.data('last-load-url') !== ajaxUrl) {
                this.loadModalContent(ajaxUrl);
            }
        };
        return BuildingLinks;
    }());
    exports.default = BuildingLinks;
});
