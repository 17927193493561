define(["require", "exports", "jquery", "TYPO3/CMS/Ufutils/OnWindowResizeFinished"], function (require, exports, $, OnWindowResizeFinished_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var AdditionalPagesDropdown = /** @class */ (function () {
        function AdditionalPagesDropdown() {
            this.additionalPagesDropdownContainer = $('.navbar-breadcrumb .dropdown.clipped');
        }
        AdditionalPagesDropdown.prototype.addItem = function (breadcrumbItem) {
            this.initAdditionalPagesDropdown();
            var dropdownLink = this.buildAdditionalPagesDropdownLink(breadcrumbItem);
            this.additionalPagesDropdown.append(dropdownLink);
        };
        AdditionalPagesDropdown.prototype.initIfHasItems = function () {
            if (!this.hasItems()) {
                return;
            }
            this.additionalPagesDropdownContainer.removeClass('d-none');
        };
        AdditionalPagesDropdown.prototype.buildAdditionalPagesDropdownLink = function (breadcrumbItem) {
            var dropdownLink = $('<a/>');
            var breadcrumbItemLink = breadcrumbItem.find('> a');
            dropdownLink.attr('href', breadcrumbItemLink.attr('href'));
            dropdownLink.attr('class', 'dropdown-item');
            dropdownLink.append(breadcrumbItemLink.html());
            return dropdownLink;
        };
        AdditionalPagesDropdown.prototype.hasItems = function () {
            return !!this.additionalPagesDropdown;
        };
        AdditionalPagesDropdown.prototype.initAdditionalPagesDropdown = function () {
            if (this.additionalPagesDropdown) {
                return;
            }
            var additionalPagesDropdownTrigger = $('<a aria-expanded="false" aria-haspopup="true"' +
                ' class="dropdown-toggle" href="#" id="breadcrumbsCollapsedPages" role="button">' +
                '...</a>');
            additionalPagesDropdownTrigger.attr('title', this.additionalPagesDropdownContainer.attr('data-title'));
            this.additionalPagesDropdown = $('<div aria-labelledby="breadcrumbsCollapsedPages" class="dropdown-menu"/>');
            this.additionalPagesDropdownContainer.append(additionalPagesDropdownTrigger);
            this.additionalPagesDropdownContainer.append(this.additionalPagesDropdown);
        };
        return AdditionalPagesDropdown;
    }());
    var BreadcrumbsHover = /** @class */ (function () {
        function BreadcrumbsHover() {
            this.delayHover = 300;
            this.delayLeave = 300;
            this.hoveredItem = null;
            this.isTouchDevice = false;
            this.skipClickEvent = false;
            this.timeoutIdentifier = null;
            this.wasTouched = false;
            this.additionalPagesDropdown = new AdditionalPagesDropdown();
            this.breadcrumbSubnavContainer = $('.navbar-breadcrumb-sub .container');
            this.breadcrumbItems = $('.breadcrumb-item');
            this.activeItem = $('.breadcrumb-item.active');
        }
        BreadcrumbsHover.prototype.init = function () {
            var _this = this;
            this.breadcrumbItems.each(function (index, element) {
                _this.initItem(element);
            });
            this.additionalPagesDropdown.initIfHasItems();
            $('body').on('touchstart', function () {
                _this.isTouchDevice = true;
                _this.leaveItem();
            });
        };
        BreadcrumbsHover.prototype.hoverAdd = function (breadcrumbItem) {
            this.hoverRemove();
            var dropdownMenu = breadcrumbItem.find('.dropdown-menu');
            var breadcrumbToggle = breadcrumbItem.find('.dropdown-toggle');
            breadcrumbItem.addClass('show');
            this.activeItem.removeClass('show');
            dropdownMenu.addClass('show');
            this.breadcrumbSubnavContainer.css('visibility', 'hidden');
            breadcrumbToggle.attr('aria-expanded', 'true');
            this.hoveredItem = breadcrumbItem;
        };
        BreadcrumbsHover.prototype.hoverRemove = function () {
            if (!this.hoveredItem) {
                return;
            }
            var breadcrumbItem = this.hoveredItem;
            var dropdownMenu = breadcrumbItem.find('.dropdown-menu');
            var breadcrumbToggle = breadcrumbItem.find('.dropdown-toggle');
            breadcrumbItem.removeClass('show');
            dropdownMenu.removeClass('show');
            breadcrumbToggle.attr('aria-expanded', 'false');
            this.breadcrumbSubnavContainer.css('visibility', 'visible');
        };
        BreadcrumbsHover.prototype.initItem = function (element) {
            var _this = this;
            var breadcrumbItem = $(element);
            if (breadcrumbItem.hasClass('active') || breadcrumbItem.hasClass('no-hover')) {
                return;
            }
            var isAdditionalItemDropdown = breadcrumbItem.hasClass('clipped');
            if (!isAdditionalItemDropdown && breadcrumbItem.hasClass('d-none')) {
                this.additionalPagesDropdown.addItem(breadcrumbItem);
            }
            breadcrumbItem.mouseenter(function () {
                if (_this.isTouchDevice) {
                    return;
                }
                _this.resetTimeout();
                _this.timeoutIdentifier = window.setTimeout(function () { return _this.hoverAdd(breadcrumbItem); }, _this.delayHover);
            });
            breadcrumbItem.mouseleave(function () {
                if (_this.isTouchDevice) {
                    return;
                }
                _this.resetTimeout();
                if (_this.wasTouched) {
                    return;
                }
                _this.timeoutIdentifier = window.setTimeout(function () { return _this.leaveItem(); }, _this.delayLeave);
            });
            var dropdownMenu = breadcrumbItem.find('.dropdown-menu');
            if (!isAdditionalItemDropdown && !dropdownMenu.length) {
                return;
            }
            this.activeItem.on('touchstart', function () {
                _this.isTouchDevice = true;
                _this.leaveItem();
            });
            breadcrumbItem.on('touchstart', function (e) {
                _this.isTouchDevice = true;
                e.stopPropagation();
                if (_this.isHovered(breadcrumbItem)) {
                    return;
                }
                _this.wasTouched = true;
                _this.skipClickEvent = true;
                _this.resetTimeout();
                _this.hoverAdd(breadcrumbItem);
            });
            breadcrumbItem.click(function (e) {
                if (!_this.skipClickEvent) {
                    return;
                }
                _this.skipClickEvent = false;
                e.preventDefault();
            });
        };
        BreadcrumbsHover.prototype.isHovered = function (breadcrumbItem) {
            return breadcrumbItem.hasClass('show');
        };
        BreadcrumbsHover.prototype.leaveItem = function () {
            this.wasTouched = false;
            this.hoverRemove();
            this.activeItem.addClass('show');
        };
        BreadcrumbsHover.prototype.resetTimeout = function () {
            if (!this.timeoutIdentifier) {
                return;
            }
            window.clearTimeout(this.timeoutIdentifier);
        };
        return BreadcrumbsHover;
    }());
    var BreadcrumbsFixedHeight = /** @class */ (function () {
        function BreadcrumbsFixedHeight() {
            this.isFolded = true;
            this.maxHeight = 0;
            this.maxLines = 2;
            this.subnavHeight = 0;
            var subnav = $('.navbar-breadcrumb-sub');
            this.subnavItems = subnav.find('.subnav-item-wrapper');
            this.unfoldButton = subnav.find('.unfold-nav');
        }
        BreadcrumbsFixedHeight.prototype.init = function () {
            var _this = this;
            this.initFolding();
            this.unfoldButton.click(function (e) {
                e.preventDefault();
                _this.toggleFold();
            });
            OnWindowResizeFinished_1.default.addListener(function () {
                _this.recalculateHeights();
            });
        };
        BreadcrumbsFixedHeight.prototype.calculateHeights = function () {
            this.maxHeight = 0;
            this.subnavHeight = 0;
            var singleItemHeight = this.detectSingleItemHeight();
            // No items, nothing to do!
            if (!singleItemHeight) {
                return;
            }
            this.maxHeight = Math.ceil(singleItemHeight * this.maxLines);
            this.subnavHeight = Math.floor(this.detectSubnavHeight());
        };
        BreadcrumbsFixedHeight.prototype.detectSingleItemHeight = function () {
            return this.subnavItems.find('.subnav-item:first-child').outerHeight();
        };
        BreadcrumbsFixedHeight.prototype.detectSubnavHeight = function () {
            return this.subnavItems.outerHeight();
        };
        BreadcrumbsFixedHeight.prototype.fold = function () {
            this.subnavItems.css('max-height', this.maxHeight + 1);
            this.unfoldButton.removeClass('unfolded');
            this.isFolded = true;
        };
        BreadcrumbsFixedHeight.prototype.initFolding = function () {
            this.calculateHeights();
            if (!this.needsFolding()) {
                return;
            }
            this.unfoldButton.show();
            if (this.isFolded) {
                this.fold();
                return;
            }
            this.unfold();
        };
        BreadcrumbsFixedHeight.prototype.needsFolding = function () {
            return this.subnavHeight > this.maxHeight;
        };
        BreadcrumbsFixedHeight.prototype.recalculateHeights = function () {
            this.subnavItems.css('max-height', '');
            this.unfoldButton.hide();
            this.initFolding();
        };
        BreadcrumbsFixedHeight.prototype.toggleFold = function () {
            if (this.isFolded) {
                this.unfold();
                return;
            }
            this.fold();
        };
        BreadcrumbsFixedHeight.prototype.unfold = function () {
            this.subnavItems.css('max-height', this.subnavHeight);
            this.unfoldButton.addClass('unfolded');
            this.isFolded = false;
        };
        return BreadcrumbsFixedHeight;
    }());
    $(function () {
        var breadcrumbsHover = new BreadcrumbsHover();
        breadcrumbsHover.init();
        var breadcrumbsHeight = new BreadcrumbsFixedHeight();
        breadcrumbsHeight.init();
    });
});
