import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// We have to use require here to load synchronously after window.jQuery was defined.
// All other methods like mix.autoload() or webpack ProvidePlugin have failed :(
require('bootstrap-autocomplete/dist/latest/bootstrap-autocomplete');

$('.tx-solr-suggest').each(function() {
  const suggestField = $(this);
  const searchForm = suggestField.closest('form');
  const suggestUrl = searchForm.attr('data-suggest');

  suggestField.autoComplete({
    resolver: 'custom',
    events: {
      search: function(qry, callback) {
        $.ajax(
          suggestUrl,
          {
            method: 'GET',
            data: {'tx_solr': {queryString: qry}},
            dataType: 'json'
          }
        ).done(function(res) {
          callback(res.suggestions);
        });
      }
    },
    formatResult: function(item) {
      return {
        text: item,
      };
    }
  }).on('autocomplete.select', () => {
    searchForm.submit();
  });
});

$('#tx-solr-search-functions').find('input[type="checkbox"]').change((e) => {
  $(e.target).closest('form').submit();
});

// Expand all hierarchy facets by default
$('.facet-type-hierarchy.panel-collapse').each(function() {
  $(this).show();
});

