define(["require", "exports", "jquery"], function (require, exports, $) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var backToTopButton = $('.back-to-top');
    $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
            backToTopButton.addClass('show');
        }
        else {
            backToTopButton.removeClass('show');
        }
    });
    backToTopButton.on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
    });
});
