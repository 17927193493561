define(["require", "exports", "jquery"], function (require, exports, $) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var buttonWrapper = $('#readspeaker-button-wrapper');
    var readSpeakerLink = buttonWrapper.find('a.rsbtn_play');
    var customerId = buttonWrapper.data('customer-id');
    var body = $('body');
    // @ts-ignore
    window.rsConf = {
        params: '//cdn1.readspeaker.com/script/' + customerId + '/webReader/webReader.js?pids=wr',
        general: { usePost: true },
        settings: {
            hlword: '#307197',
            hlsent: '#6f6f6f',
            hltext: '#ffffff',
        },
        cb: {
            ui: {
                // We must use the play callback because the open callback is only triggered once :(
                play: function () {
                    body.attr('data-is-readspeaker-active', 1);
                    if (!$('#readspeaker_warning').length) {
                        buttonWrapper.append('<div id="readspeaker_warning" class="alert alert-info">' + TYPO3.lang.readspeaker_warning + '</div>');
                    }
                },
                close: function () {
                    $('#readspeaker_warning').remove();
                    body.attr('data-is-readspeaker-active', 0);
                    body.trigger('readspeakerClosed');
                },
            },
        },
    };
    $(function () {
        readSpeakerLink.one('click', function (event) {
            event.preventDefault();
            var e = document.createElement('script');
            // @ts-ignore
            e.src = window.rsConf.params;
            e.type = 'text/javascript';
            e.addEventListener('load', function () {
                // @ts-ignore
                $('#readspeaker-button .rsbtn_play')
                    .removeClass('btn btn-readspeaker btn-sm font-weight-bold')
                    .find('.listen-icon').remove();
                $('#readspeaker-button .rsplay').click();
            });
            document.getElementsByTagName('head')[0].appendChild(e);
        });
    });
    body.on('shown.bs.collapse', function (event) {
        $(event.target).removeClass('rs_skip');
    });
    body.on('hide.bs.collapse', function (event) {
        $(event.target).addClass('rs_skip');
    });
    body.on('shown.bs.tab', function (event) {
        var idSelector = $(event.target).attr('href');
        $(idSelector).removeClass('rs_skip');
    });
    body.on('hide.bs.tab', function (event) {
        var idSelector = $(event.target).attr('href');
        $(idSelector).addClass('rs_skip');
    });
});
