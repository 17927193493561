define(["require", "exports", "jquery", "./ActiveElementDetector"], function (require, exports, $, ActiveElementDetector_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.default = (function () {
        $('.accordion').each(function (index, element) {
            var accordion = $(element);
            var foldables = accordion.find('> .card-foldable');
            var activeElementDetector = new ActiveElementDetector_1.default();
            activeElementDetector.setFallbackIndex(null);
            var activeElementIdex = activeElementDetector.getActiveIndex(foldables);
            if (activeElementIdex === null) {
                return;
            }
            var activeElement = $(foldables.get(activeElementIdex));
            activeElement.find('> .collapse').collapse('show');
        });
    });
});
