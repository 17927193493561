!function() {
  var $loadMoreEventsButton = document.querySelector('.load-more-event-dates');

  if ($loadMoreEventsButton) {
    $loadMoreEventsButton.addEventListener('click', function(e) {
      e.preventDefault();

      var $hiddenEventDates = this.previousElementSibling ? this.previousElementSibling.querySelectorAll('li[style*="display:none"]') : [];

      for (var i = $hiddenEventDates.length - 1; i >= 0; i--) {
        $hiddenEventDates[i].removeAttribute('style');
      }

      this.remove();
    });
  }
}();
