define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var OnWindowResizeFinished = /** @class */ (function () {
        function OnWindowResizeFinished() {
            var _this = this;
            window.addEventListener('resize', function () {
                if (_this.resizeId) {
                    window.clearTimeout(_this.resizeId);
                }
                _this.resizeId = window.setTimeout(function () { return _this.doneResizing(); }, 300);
            });
        }
        OnWindowResizeFinished.addListener = function (callback) {
            var resizeFinishedListener = new OnWindowResizeFinished();
            resizeFinishedListener.setCallback(callback);
        };
        OnWindowResizeFinished.prototype.doneResizing = function () {
            if (!this.onResizeCallback) {
                return;
            }
            this.onResizeCallback();
        };
        OnWindowResizeFinished.prototype.setCallback = function (onResizeCallback) {
            this.onResizeCallback = onResizeCallback;
        };
        return OnWindowResizeFinished;
    }());
    exports.default = OnWindowResizeFinished;
});
