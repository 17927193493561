var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
define(["require", "exports", "jquery"], function (require, exports, $) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var UrlHashChangeHandler = /** @class */ (function () {
        function UrlHashChangeHandler() {
            this.contentIdHandlers = [];
            this.enableHashChangeOnUnfold = false;
        }
        UrlHashChangeHandler.prototype.addContentIdHandler = function (handler) {
            this.contentIdHandlers.push(handler);
        };
        UrlHashChangeHandler.prototype.getHash = function () {
            return location.hash;
        };
        UrlHashChangeHandler.prototype.init = function (handleInitialHash) {
            var _this = this;
            window.addEventListener('hashchange', function () { return _this.handleHashChange(); }, false);
            if (handleInitialHash) {
                this.handleHashChange();
            }
            this.initHashChangeOnUnfold();
        };
        UrlHashChangeHandler.prototype.handleHashChange = function () {
            this.enableHashChangeOnUnfold = false;
            this.handleHashChangeProcessor();
            this.enableHashChangeOnUnfold = true;
        };
        UrlHashChangeHandler.prototype.handleHashChangeProcessor = function () {
            var e_1, _a;
            var hash = this.getHash();
            if (!hash) {
                return;
            }
            if (hash === '#unfold-all') {
                $('.accordion .collapse').collapse('show');
                return;
            }
            if (/^#(c\d+)$/.test(hash)) {
                try {
                    for (var _b = __values(this.contentIdHandlers), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var handler = _c.value;
                        handler();
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                this.scrollToElement(hash);
                return;
            }
        };
        UrlHashChangeHandler.prototype.initHashChangeOnUnfold = function () {
            var _this = this;
            var body = $('body');
            body.on('show.bs.collapse', function (event) {
                if (!_this.enableHashChangeOnUnfold) {
                    return;
                }
                var clickedElement = $(event.target);
                var clickedFoldable = clickedElement.parent();
                if (!clickedFoldable.hasClass('card-foldable')
                    || !clickedFoldable.parent().hasClass('accordion')) {
                    return;
                }
                history.replaceState(undefined, undefined, '#' + clickedFoldable.attr('id'));
            });
            body.on('show.bs.tab', function (event) {
                if (!_this.enableHashChangeOnUnfold) {
                    return;
                }
                var clickedElement = $(event.target);
                history.replaceState(undefined, undefined, clickedElement.attr('href'));
            });
        };
        UrlHashChangeHandler.prototype.scrollToElement = function (idSelector) {
            var element = $(idSelector);
            if (!element.length) {
                return;
            }
            var topDistance = element.offset().top, header = document.getElementById('my-header');
            if (header && window.matchMedia('(max-width: 767px)').matches) {
                topDistance -= header.offsetHeight;
            }
            // TODO: wait for animations to finish instead of using timeout!
            window.setTimeout(function () {
                $('body,html').animate({
                    scrollTop: topDistance,
                }, 250);
            }, 500);
        };
        return UrlHashChangeHandler;
    }());
    exports.default = UrlHashChangeHandler;
});
