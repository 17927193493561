import jQuery from 'jquery';

(function($) {
  "use strict";

  const placeholderClass = 'tx-intdiv-responsive-image-placeholder';

  const initialize = (placeholders) => {
    placeholders.each(function() {
      const placeholder = $(this);

      calculateSize(placeholder);

      const replaceAttributes = [
        'srcset',
        'src'
      ];

      replaceAttributes.forEach((attributeName) => {
        const dataAttributeName = 'data-attr-' + attributeName;
        const attributeValue = placeholder.attr(dataAttributeName);
        if (!attributeValue) {
          return;
        }

        placeholder.attr(attributeName, attributeValue);
        placeholder.removeAttr(dataAttributeName)
      });


      placeholder.removeClass(placeholderClass);
    });
  };

  const responsiveImages = $('.' + placeholderClass);
  let viewportWidth = calculateViewportWidth();

  function calculateViewportWidth() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }

  function calculateSize(image) {
    const imgWidth = image.innerWidth();
    const imgPercentage = 100 / viewportWidth * imgWidth;
    image.attr('sizes', imgPercentage + 'vw');
  }

  function recalculate() {
    viewportWidth = calculateViewportWidth();

    responsiveImages.each(function() {
      const placeholder = $(this);
      calculateSize(placeholder);
    });
  }

  initialize(responsiveImages);

  const $window = $(window);
  $window.resize(function() {
    recalculate();
  });
  $window.on('shown.bs.collapse shown.bs.tab', () => {
    recalculate();
  });
  $window.on('responsive.image.reload', () => {
    const newImages = $('.' + placeholderClass);
    initialize(newImages);
    responsiveImages.add(newImages);
  });
  // Recalculate carousel images for optimal resolution
  $('.frame-type-textmedia .slider').each(function() {
    $(this).on('init', recalculate);
  });
}(jQuery));
