define(["require", "exports", "jquery"], function (require, exports, $) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var Teaser = /** @class */ (function () {
        function Teaser(body) {
            this.element = body;
            this.modal = this.element.find('#main-modal');
            this.loadingModalTemplate = this.modal.find('.modal-content').clone();
        }
        Teaser.prototype.init = function () {
            var teaserPageUrl = $('body').data('teaser-page-url');
            if (!teaserPageUrl) {
                return;
            }
            this.loadModalContent(teaserPageUrl);
        };
        Teaser.prototype.loadModalContent = function (teaserPageUrl) {
            var _this = this;
            this.modal.data('last-load-url', teaserPageUrl);
            this.modal.find('.modal-content').replaceWith(this.loadingModalTemplate.clone());
            $.ajax(teaserPageUrl, {
                dataType: 'html',
                success: function (modalContent) {
                    _this.showModal(modalContent);
                },
            });
        };
        Teaser.prototype.shouldShowModal = function (modalContent) {
            var $modalContent = $(modalContent);
            if ($modalContent.find('.modal-body').children().length === 0) {
                return false;
            }
            var lastChangedDate = '' + $modalContent.data('last-changed');
            var teaserPageUid = $modalContent.data('page-uid');
            var localStoreIdentifier = 'teaserTimestamp' + teaserPageUid;
            var lastTimestamp = localStorage.getItem(localStoreIdentifier);
            if (!lastTimestamp) {
                localStorage.setItem(localStoreIdentifier, lastChangedDate);
                return true;
            }
            if (lastTimestamp === lastChangedDate) {
                return false;
            }
            localStorage.setItem(localStoreIdentifier, lastChangedDate);
            return true;
        };
        Teaser.prototype.showModal = function (modalContent) {
            if (!this.shouldShowModal(modalContent)) {
                return;
            }
            var modalDialog = this.modal.find('.modal-dialog');
            modalDialog.addClass('modal-xl');
            this.modal.one('hidden.bs.modal', function () { return modalDialog.removeClass('modal-xl'); });
            this.modal.modal('show');
            if (this.element.data('teaser-is-emgergency')) {
                var modalBackdrop = this.element.find('.modal-backdrop');
                modalBackdrop.addClass('emergency-teaser');
            }
            this.modal.find('.modal-content').replaceWith(modalContent);
            $(window).trigger('responsive.image.reload');
        };
        return Teaser;
    }());
    $(function () {
        var teaser = new Teaser($('body'));
        teaser.init();
    });
});
