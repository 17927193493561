"use strict";
var easterEggInitialized = false;
var initClickEasterEgg = function () {
    if (easterEggInitialized) {
        return;
    }
    easterEggInitialized = true;
    var body = document.getElementsByTagName('body')[0];
    var clickTimeframe = 3000;
    var requiredNumberOfClicks = 15;
    var eastereggUrl = body.getAttribute('data-easteregg-url');
    if (!eastereggUrl) {
        return;
    }
    var numberOfClicks = [];
    body.addEventListener('click', function () {
        // First click is always counted
        if (numberOfClicks.length === 0) {
            numberOfClicks.push(new Date().getTime());
            return;
        }
        // Clicks did not happen within the timeframe -> reset
        var timeSinceFirstClick = new Date().getTime() - numberOfClicks[0];
        if (timeSinceFirstClick > clickTimeframe) {
            numberOfClicks = [];
            numberOfClicks.push(new Date().getTime());
            return;
        }
        // Required number of clicks not reached -> count click.
        if (numberOfClicks.length < (requiredNumberOfClicks - 1)) {
            numberOfClicks.push(new Date().getTime());
            return;
        }
        numberOfClicks = [];
        window.open(eastereggUrl, '_blank');
    });
};
if (document.readyState !== 'loading') {
    initClickEasterEgg();
}
else {
    document.addEventListener('DOMContentLoaded', function () {
        initClickEasterEgg();
    });
}
