define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var ActiveElementDetector = /** @class */ (function () {
        function ActiveElementDetector() {
            this.fallbackIndex = 0;
        }
        ActiveElementDetector.prototype.getActiveIndex = function (elements) {
            this.elements = elements;
            if (/sword_list/.test(location.search)) {
                return this.detectElementIndex('.ce-sword');
            }
            // For content element hashes like #c343: find content element in tab.
            if (/^#(c\d+)$/.test(location.hash)) {
                return this.detectElementIndex(location.hash);
            }
            return this.fallbackIndex;
        };
        ActiveElementDetector.prototype.setFallbackIndex = function (value) {
            this.fallbackIndex = value;
        };
        ActiveElementDetector.prototype.detectElementIndex = function (cssSelector) {
            var tabIndex = 0;
            var tabIndexWithElement = this.fallbackIndex;
            this.elements.each(function (index, element) {
                var tab = $(element);
                if (tab.filter(cssSelector).length > 0 || tab.find(cssSelector).length > 0) {
                    tabIndexWithElement = tabIndex;
                    // Break the loop
                    return false;
                }
                tabIndex++;
                // @ts-ignore
                return;
            });
            return tabIndexWithElement;
        };
        return ActiveElementDetector;
    }());
    exports.default = ActiveElementDetector;
});
