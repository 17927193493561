"use strict";
var header = document.getElementById('my-header'), headerHeight = header ? header.offsetHeight : 76, pageContent = document.getElementById('my-page');
function addPaddingToPage() {
    if (window.matchMedia('(max-width: 767px)').matches && header && pageContent) {
        pageContent.style.marginTop = headerHeight + 'px';
    }
    else {
        pageContent.removeAttribute('style');
    }
}
document.addEventListener('DOMContentLoaded', addPaddingToPage);
window.addEventListener('resize', addPaddingToPage);
window.addEventListener('scroll', function () {
    if (window.pageYOffset > 200) {
        header.classList.add('header--small');
    }
    else {
        header.classList.remove('header--small');
    }
});
