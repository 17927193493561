var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
define(["require", "exports"], function (require, exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.parseSearchStrings = exports.getSearchWordsFromUrl = exports.buildFilterRegexes = void 0;
    function buildFilterRegexes(searchStringsSanitized) {
        var e_1, _a;
        var filterRegexes = [];
        try {
            for (var searchStringsSanitized_1 = __values(searchStringsSanitized), searchStringsSanitized_1_1 = searchStringsSanitized_1.next(); !searchStringsSanitized_1_1.done; searchStringsSanitized_1_1 = searchStringsSanitized_1.next()) {
                var sanitizedSearchString = searchStringsSanitized_1_1.value;
                var filterRegex = '.*' + sanitizedSearchString + '.*';
                filterRegexes.push(new RegExp(filterRegex, 'ig'));
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (searchStringsSanitized_1_1 && !searchStringsSanitized_1_1.done && (_a = searchStringsSanitized_1.return)) _a.call(searchStringsSanitized_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return filterRegexes;
    }
    exports.buildFilterRegexes = buildFilterRegexes;
    function getSearchWordsFromUrl() {
        var searchWords = [];
        var params = new URLSearchParams(decodeURI(window.location.search));
        params.forEach(function (value, key) {
            if (key.startsWith('sword_list')) {
                searchWords.push(value);
            }
        });
        return searchWords;
    }
    exports.getSearchWordsFromUrl = getSearchWordsFromUrl;
    function parseSearchStrings(searchString) {
        var e_2, _a;
        var searchStrings = searchString.split(/[,;\s]+/);
        var searchStringsSanitized = [];
        try {
            for (var searchStrings_1 = __values(searchStrings), searchStrings_1_1 = searchStrings_1.next(); !searchStrings_1_1.done; searchStrings_1_1 = searchStrings_1.next()) {
                var searchStringSanitized = searchStrings_1_1.value;
                if (typeof searchStringSanitized === 'string') {
                    searchStringSanitized = searchStringSanitized.replace(/[.*+?^${}()|[\]\\:_!"§%&=`´°<>]/g, '');
                    if (searchStringSanitized !== '') {
                        searchStringsSanitized.push(searchStringSanitized);
                    }
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (searchStrings_1_1 && !searchStrings_1_1.done && (_a = searchStrings_1.return)) _a.call(searchStrings_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return searchStringsSanitized;
    }
    exports.parseSearchStrings = parseSearchStrings;
});
